import React from "react";
import { useEffect, useState } from "react";
import { Image, Modal, Form, Button, InputGroup } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
    addToCart,
    decreaseFromCart,
    clearCart,
    getTotal,
} from "../../slices/Cart/reducer";
import {
    addOrder as onAddOrder,
    resetOrderApiResponseFlag as onResetOrderApiResponseFlag,
    addNotification as onAddNotification,
    createPayment as onCreatePayment
} from "../../slices/thunks";
import { MdOutlineAddBox, MdDelete } from "react-icons/md";
import { TbPointFilled } from "react-icons/tb";
import { FaArrowLeft } from "react-icons/fa6";
import "../../assets/styles/Payment.css";
import "../../assets/styles/Global.css";
import RevoultIcon from "../../assets/images/revoult-icon.png";
import AppleIcon from "../../assets/images/apple-pay.png";
import GoogleIcon from "../../assets/images/google-pay.png";
import CashIcon from "../../assets/images/wallet.png";
import CardIcon from "../../assets/images/card.png";
import TipIcon from "../../assets/images/tip.png";
import { FaCheckCircle } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../config/config.json'

const Payment = () => {
    const { cartItems, cartTotalAmout, orderStatusCode, cart, cartTotalVat, cardNetAmount, orderMessage, paymentResponse, paymentStatusCode } = useSelector(
        (state) => ({
            cart: state.cart,
            cartItems: state.cart.cartItems,
            cartTotalAmout: state.cart.cartTotalAmout,
            cardNetAmount: state.cart.cardNetAmount,
            cartTotalVat: state.cart.cartTotalVat,
            orderStatusCode: state.orders.orderStatusCode,
            orderMessage: state.orders.orderMessage,
            paymentResponse: state.orders.paymentResponse,
            paymentStatusCode: state.orders.paymentStatusCode,
        })
    );
    // const stripePromise = loadStripe('pk_test_bSsVqckNhSpx3g5Wd7mbdBUY00dYPapUqp');
    const stripe = useStripe();
    const elements = useElements();
    const [assetId, setAssetId] = useState("");
    // const [orderStatus, setOrderStatus] = useState(false);
    const [isOrderAccepted, setIsOrderAccepted] = useState(false);
    const [show, setShow] = useState(false);
    const [assetInfo, setAssetInfo] = useState('');
    const [ordersMessage, setOrderMessage] = useState('');
    const [instruction, setInstruction] = useState('');
    const [tipsData, setTipAmount] = useState({});
    const [allCardItems, setAllCardItems] = useState({});
    const [open, setOpen] = useState(false);
    const [paymentError, setPaymentError] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    let totalVat = 0;
    let totalAmount = 0;

    const handleSubmitPayment = async (event) => {
        event.preventDefault();
        let amount = event.target[1].value;
        let paymentType = event.target[2].value;
        const { token, error } = await stripe.createToken(elements.getElement(CardElement));
        if (error) {
            setPaymentError('Please enter card details.');
        } else {
            let assetInfoData = localStorage.getItem("assetInfoData");
            if (assetInfoData) {
                assetInfoData = JSON.parse(assetInfoData)
                const orderData = {
                    amount: amount * 100,
                    assetId: assetInfoData._id,
                    venueId: assetInfoData.venueId
                };
                fetch(config.API_URL + "/reservation/payment/charge", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(orderData)
                })
                    .then((res) => res.json())
                    .then(async (dataCharge) => {
                        if (dataCharge.statusCode == 200) {
                            const result = await stripe.confirmCardPayment(dataCharge.data.client_secret, {
                                payment_method: {
                                    card: elements.getElement(CardElement),
                                    billing_details: {
                                        "address": {
                                            "city": assetInfoData.venueInfo.location.city,
                                            "country": "GR",
                                            "line1": assetInfoData.venueInfo.location.address,
                                            "line2": assetInfoData.venueInfo.location.country,
                                            "postal_code": assetInfoData.venueInfo.location.zip,
                                            "state": assetInfoData.venueInfo.location.state
                                        },
                                        "email": assetInfoData.venueInfo.email,
                                        "name": assetInfoData.venueInfo.name,
                                        "phone": null
                                    },
                                },
                            });
                            if (result.error) {
                                setPaymentError('Payment failed');
                            } else if (result.paymentIntent.status === 'succeeded') {
                                const orderData = {
                                    assetId: assetId,
                                    menu: cartItems,
                                    paymentMethod: "Stripe",
                                    tip: (!isNaN(tipsData.tip)) ? tipsData.tip : 0,
                                    instruction: instruction,
                                    orderId: result.paymentIntent.description
                                };
                                await dispatch(onAddOrder(orderData));
                                let payload = {
                                    assetId: assetInfo._id,
                                    venueAreasId: assetInfo.venueAreasId,
                                    venueId: assetInfo.venueId,
                                    title: `New Reservation Order`,
                                    message: `Hello KDS new order arrived on ${assetInfo.venueInfo.name} asset table ${assetInfo.assetName}`,
                                    type: "NewOrder",
                                }
                                await dispatch(onAddNotification(payload))
                                setPaymentError(null);
                            }
                        } else {
                            if (paymentStatusCode == 400) {
                                setPaymentError(paymentResponse.message);
                            } else {
                                setPaymentError("Payment init failed!");
                            }
                        }
                    });
            } else {
                setPaymentError("Asset  not found!");
            }
        }
    };

    const [formData, setFormData] = useState({
        cardNumber: "",
        cardHolder: "",
        expirationDate: "",
        cvv: "",
    });

    const getInstructions = (e) => {
        setInstruction(e)
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const addTip = (tip, totaAmount) => {
        let tipAmount = (totaAmount * tip) / 100;
        setTipAmount({ tip: tip, tipAmount: tipAmount, totaAmount: totaAmount + tipAmount })
    };

    const removeTip = (tip, totaAmount) => {
        setTipAmount({})
    };

    useEffect(() => {
        dispatch(getTotal());
    }, [cartItems, dispatch]);


    const addInstructions = (e, item) => {
        let cardItem = localStorage.getItem("cartItems");
        if (cardItem) {
            cardItem = JSON.parse(cardItem)
            cardItem = cardItem.map((cItem) => {
                if (cItem._id == item._id)
                    cItem.instruction = e;
                return cItem;
            })
            localStorage.setItem('cartItems', JSON.stringify(cardItem));
        }
    };

    useEffect(() => {
        setAssetId(localStorage.getItem("assetId"));
    }, []);

    useEffect(() => {
        let assetInfo = localStorage.getItem("assetInfoData");
        if (assetInfo) {
            setAssetInfo(JSON.parse(assetInfo));
            assetInfo = JSON.parse(assetInfo);
            if (assetInfo.venueInfo.setting !== undefined)
                if (!assetInfo.venueInfo.setting.modelB) {
                    navigate(`/menu?assetId=${localStorage.getItem("assetId")}`);
                }
        }
    }, [navigate]);

    useEffect(() => {
        if (orderStatusCode != null) {
            setShow(true);
            if (orderStatusCode === 200) {
                setOrderMessage(orderMessage);
                setIsOrderAccepted(true);
                //dispatch(clearCart());
                const timeoutId = setTimeout(() => {
                    dispatch(onResetOrderApiResponseFlag());
                    // navigate(`/menu?assetId=${assetId}`)
                    //navigate(`/review`);
                    navigate(`/print?assetId=${assetId}`);
                }, 2000);
                // Cleanup the timeout to avoid memory leaks
                return () => clearTimeout(timeoutId);
            } else {
                setOrderMessage(orderMessage);
                const timeoutId = setTimeout(() => {
                    dispatch(onResetOrderApiResponseFlag());
                    navigate(`/menu?assetId=${assetId}`);
                }, 3000);

                return () => clearTimeout(timeoutId);
            }
        }
    }, [orderStatusCode, dispatch, assetId, navigate]);

    // Handle Add To Cart
    const handleAddToCart = (item) => {
        dispatch(addToCart(item));
    };

    // Handle Remove From Cart
    const handleDecreseFromCart = (id) => {
        dispatch(decreaseFromCart(id));
    };

    const handleBackClick = () => {
        navigate(`/menu?assetId=${assetId}`);
    };

    const handleAddOrder = (paymentMethod) => {
        // setSpin(true);
        let cartItems = localStorage.getItem("cartItems");
        let clientInfo = localStorage.getItem("clientInfo");
        const orderData = {
            assetId: assetId,
            menu: JSON.parse(cartItems),
            paymentMethod: paymentMethod,
            tip: (!isNaN(tipsData.tip)) ? tipsData.tip : 0,
            instruction: instruction,
            clientInfo: (clientInfo) ? JSON.parse(clientInfo) : "",
        };
        dispatch(onAddOrder(orderData));
        let payload = {
            assetId: assetInfo._id,
            venueAreasId: assetInfo.venueAreasId,
            venueId: assetInfo.venueId,
            title: `New Reservation Order`,
            message: `Hello KDS new order arrived on ${assetInfo.venueInfo.name} asset table ${assetInfo.assetName}`,
            type: "NewOrder",
        }
        dispatch(onAddNotification(payload))
    };

    return (
        <>
            <div className="d-flex justify-content-center p-4 px-lg-5">
                <div className="_payment-container">
                    {/* Navigation */}
                    <div className="d-flex align-items-center">

                        <div
                            onClick={handleBackClick}
                            className="d-flex align-items-center border rounded-pill p-1 _cursor-pointer"
                        >
                            <FaArrowLeft
                                size={18}
                                className="text-muted _cursor-pointer mx-1"
                            />
                            <span className="mx-1 fw-bold">Back</span>
                        </div>
                        <h6 className="_font-l p-0 m-0 px-3">{(assetInfo.name) ? assetInfo.name : "AwinSoft"}</h6>
                        {/* <Image className="w-100" src={(assetInfo.floorPlanImage) ? assetInfo.floorPlanImage : ""} /> */}
                    </div>
                    {/* items */}
                    <div className="_bottom-shadow border _rounded-medium mt-4">
                        <div className="p-2">
                            {cartItems.map((item, index) => (
                                <div key={index} className="py-1">
                                    <div className="d-flex justify-content-between ">
                                        <div className="d-flex align-items-center">
                                            <TbPointFilled className="text-success" />
                                            <h6 className="p-0 m-0">{item.name}</h6>
                                        </div>
                                        <div className="_font-m d-flex">
                                            <div
                                                className="border mx-2 text-center d-flex _cursor-pointer"
                                                style={{ height: "20px" }}
                                            >
                                                <span
                                                    onClick={() => handleDecreseFromCart(item._id)}
                                                    className="px-1 cursor-pointer"
                                                >
                                                    -
                                                </span>
                                                <div style={{ width: "1rem" }}>{item.quantity}</div>
                                                <span
                                                    onClick={() => handleAddToCart(item)}
                                                    className="px-1 cursor-pointer"
                                                >
                                                    +
                                                </span>
                                            </div>
                                            <p className="fw-bold p-0 m-0" style={{ width: "4rem" }}>
                                                &#8364;{" "}
                                                {(item.quantity * item.price) % 1 !== 0
                                                    ? (item.quantity * item.price).toFixed(2)
                                                    : item.quantity * item.price}
                                            </p>
                                        </div>
                                    </div>
                                    <p className="_font-s p-0 px-3 m-0 text-muted w-75">
                                        {item.description}
                                        <Form.Control type="text"
                                            placeholder="Leave Instructions"
                                            defaultValue={item.instruction || ""}
                                            onChange={(event) => addInstructions(event.target.value, item)}
                                        />
                                    </p>
                                    {/* <div className="d-inline align-items-center px-3 _cursor-pointer">
                    <span className="_font-s p-0 m-0 w-75">Customize</span> 
                    <IoIosArrowDown className="mx-1" size={10} />
                  </div> */}
                                </div>
                            ))}
                        </div>
                        <div
                            onClick={handleBackClick}
                            className="d-flex justify-content-between p-2 border-top text-muted cursor-pointer _cursor-pointer"
                        >
                            <div className="px-3">
                                <span className="_font-m">Add More Items</span>
                            </div>
                            <div>
                                <MdOutlineAddBox />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between p-2 border-top text-muted cursor-pointer _cursor-pointer">
                            <div className="px-3">
                                <span className="_font-m" onClick={() => setOpen(!open)} aria-expanded={open}>Add Instructions</span>
                            </div>
                            <Collapse in={open}>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="inputGroup-sizing-default">
                                        Instructions
                                    </InputGroup.Text>
                                    <Form.Control as="textarea" onChange={(event) => getInstructions(event.target.value)}
                                        aria-label="Default"
                                        placeholder="Leave a instructions here"
                                        aria-describedby="inputGroup-sizing-default"
                                    />
                                </InputGroup>
                            </Collapse>
                            <div>
                                <MdOutlineAddBox onClick={() => setOpen(!open)} aria-expanded={open} />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between p-2 border-top text-muted cursor-pointer _cursor-pointer">
                            <div
                                className="border p-1 d-flex justify-content-center align-items-center"
                                style={{ width: "2rem", height: "2rem" }}
                            >
                                <Image className="" src={TipIcon} fluid />
                            </div>
                            <div className="px-3">
                                <span className="_font-m">Say thanks with a Tip</span>
                                <div className="_font-m"> <Button onClick={() => addTip(2, cartTotalAmout)}
                                    variant="success"
                                    size="sm"
                                >
                                    2 %
                                </Button>{' '}
                                    <Button onClick={() => addTip(5, cartTotalAmout)}
                                        variant="success"
                                        size="sm"
                                    >
                                        5 %
                                    </Button>{' '}
                                    <Button onClick={() => addTip(10, cartTotalAmout)}
                                        variant="success"
                                        size="sm"
                                    >
                                        10 %
                                    </Button>{' '}<Button onClick={() => addTip(15, cartTotalAmout)}
                                        variant="success"
                                        size="sm"
                                    >
                                        15 %
                                    </Button></div>
                            </div>
                            <div>
                                <MdOutlineAddBox />
                            </div>
                        </div>
                    </div>

                    {/* Bill Details */}
                    <h6 className="py-2 _font-l">Bill Details</h6>
                    <div className="_bottom-shadow border _rounded-medium">
                        <div className="d-flex justify-content-between p-2 px-4">
                            <h6 className="p-0 m-0 text-muted">Items Net Value</h6>
                            <h6 className="p-0 m-0">
                                &#8364;{" "}
                                {cardNetAmount}
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between p-2 px-4">
                            <h6 className="p-0 m-0 text-muted">
                                Vat and Restaurant Charges
                            </h6>
                            <h6 className="p-0 m-0">&#8364; {cartTotalVat}</h6>
                        </div>
                        <div className="d-flex justify-content-between p-2 px-4">
                            <h6 className="p-0 m-0 text-muted">
                                Tip {tipsData.tip}%
                                {(!isNaN(tipsData.tip)) ?
                                    <Button onClick={() => removeTip(tipsData.tip, (Math.floor(tipsData.tipAmount * 100) / 100))}
                                        variant="danger"
                                        size="sm"
                                    >
                                        <MdDelete />
                                    </Button>
                                    : ""}

                            </h6>
                            <h6 className="p-0 m-0">&#8364; {(!isNaN(tipsData.tipAmount)) ? (Math.floor(tipsData.tipAmount * 100) / 100) : 0}</h6>
                        </div>
                        {/* <div className="d-flex justify-content-between p-2 px-4">
              <h6 className="p-0 m-0 text-muted">Items Total</h6>
              <h6 className="p-0 m-0">
                &#8364;{" "}
                {cartTotalAmout % 1 !== 0
                  ? cartTotalAmout.toFixed(2)
                  : cartTotalAmout}
              </h6>
            </div> */}
                        <div className="d-flex justify-content-between p-2 border-top px-4">
                            <h6>To Pay</h6>
                            <h6 className="fw-bold">
                                &#8364;{" "}
                                {/* {(!isNaN(tipsData.tipAmount))
                  ? (Math.floor(tipsData.totaAmount * 100) / 100)
                  : cartTotalAmout.toFixed(2)} */}
                                {totalAmount = (!isNaN(tipsData.tipAmount))
                                    ? (Math.floor(tipsData.totaAmount * 100) / 100)
                                    : cartTotalAmout.toFixed(2)}
                            </h6>
                        </div>

                    </div>

                    {/* Online Payment */}
                    {/* <h6 className="_font-l py-2">Online Payment</h6> */}

                    {(assetInfo) ? (assetInfo.venueInfo.setting.isOnlineOption) ?
                        <div className="_bottom-shadow border _rounded-medium my-1">
                            <div className="d-flex p-2 cursor-pointer">
                                <div
                                    className="border d-flex justify-content-center align-items-center overflow-hidden"
                                    style={{ width: "2rem", height: "2rem" }}
                                >
                                    <Image className="h-100" src={CardIcon} />
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <h5 className="p-0 m-0 px-2 text-muted">Card Payment</h5>
                                </div>
                            </div>
                            <div className="p-2 ">
                                <Form className="" onSubmit={handleSubmitPayment}>
                                    <CardElement />
                                    <input type="hidden" name="amount" defaultValue={totalAmount} />
                                    <input type="hidden" name="paymentOption" defaultValue="Stripe" />
                                    <div className="d-flex justify-content-center mt-2">
                                        <button type="submit" className="_bg-col-voilet text-light rounded border-0 py-1">
                                            €{totalAmount}  Pay Now
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-center mt-2">
                                        {paymentError && <Alert key='danger' variant='danger'>{paymentError}
                                        </Alert>}</div>
                                </Form>
                            </div>
                        </div>
                        : "" : ""}

                    {(assetInfo) ? (assetInfo.venueInfo.setting.isCashOption) ?

                        <div className="_bottom-shadow border _rounded-medium my-1">
                            <div className="d-flex p-2 cursor-pointer">
                                <div
                                    className="border d-flex justify-content-center align-items-center overflow-hidden"
                                    style={{ width: "2rem", height: "2rem" }}
                                >
                                    <Image className="h-100" src={CashIcon} />
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <h5 className="p-0 m-0 px-2 text-muted">Cash</h5>
                                </div>
                            </div>
                            <div className="p-2 ">
                                <p className="p-0 m-0 _font-s">Pay cash to delivery partner or waiter.</p>
                                <div className="d-flex justify-content-center mt-2">
                                    <button type="submit"
                                        className="_bg-col-voilet text-light rounded border-0 py-1"
                                        onClick={() => handleAddOrder("CashPay")}
                                    >
                                        Pay Now
                                    </button>
                                </div>
                            </div>
                        </div>
                        : "" : ""}

                </div>
            </div>
            <Modal show={show} fullscreen={true}>
                <Modal.Body className="d-flex align-items-center justify-content-center h-100">
                    {isOrderAccepted ? (
                        <div className="text-center">
                            <FaCheckCircle size={40} className="_text-light-green" />
                            <h4 className="text-muted p-0 m-0 py-3">
                                {ordersMessage}
                            </h4>
                        </div>
                    ) : (
                        <div className="text-center">
                            <ImCross
                                size={40}
                                className="text-light bg-danger rounded-circle p-2"
                            />
                            <h4 className="text-muted p-0 m-0 py-3">{ordersMessage}</h4>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Payment;
