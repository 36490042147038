import React, { useEffect, useState } from "react";
import "../../assets/styles/Menu.css";
import { useNavigate } from "react-router-dom";
import { Image, Dropdown, Modal, Card, Row, Col, InputGroup, Form, Button, Table } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { LuSquareDot, LuClock, LuShoppingCart } from "react-icons/lu";
import backgroundImageUrl from "../../assets/images/banner.png";
import { IoIosAddCircleOutline, IoIosAddCircle } from "react-icons/io";
import { CiCircleMinus } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { IoPlay } from "react-icons/io5";
import { IoMdArrowRoundBack, IoIosCall } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import CategoryModal from "../../components/Modals/CategoryModal/CategoryModal";
import {
  getMenu as onGetMenu,
  getCategories as onGetCategories,
  addNotification as onAddNotification,
  getAssetInfo as onGetAssetInfo,
} from "../../slices/thunks";
import { addToCart, decreaseFromCart } from "../../slices/Cart/reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { LuVegan } from "react-icons/lu";
import { FaArrowLeft } from "react-icons/fa6";

const Menu = () => {
  const { menu, menuAddUpdate, categories, categoriesAddUpdate, cartItems, asset } =
    useSelector((state) => ({
      menu: state.menus.menu,
      status: state.menus.status,
      menuMsgResponse: state.menus.menuMsgResponse,
      menuAddUpdate: state.menus.menuAddUpdate,
      categoriesAddUpdate: state.categories.categoriesAddUpdate,
      categories: state.categories.categories,
      cartItems: state.cart.cartItems,
      notification: state.notifications.notification,
      asset: state.assets.asset
    }));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [menuList, setMenuList] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [show, setShow] = useState(false);
  const [addBtnStyle, setAddBtnStyle] = useState("d-none");
  const [itemCaptionStyle, setItemCaptionStyle] = useState("d-block");
  const [modelItem, setModelItem] = useState({});
  const [popupItem, setPopupItem] = useState({});
  const [modelSetting, setModelSetting] = useState(false);
  const [assetIds, setAssetIds] = useState("");
  const [filterTerm, setFilterTerm] = useState('');
  const [videoShow, setVideoShow] = useState(false);
  const [isCategoryModal, setIsCategoryModal] = useState(false);
  const [isMenuCardView, setIsMenuCardView] = useState(false);
  const [showCategoryOnMenu, setShowCategoryOnMenu] = useState(false);
  const [showCategoryOnPopup, setShowCategoryOnPopup] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [assetInfo, setAssetInfo] = useState({});
  const [isScanModel, setIsScanModel] = useState(true);
  const [nestedModal, setNestedModal] = useState(false);
  const [isEnablePopupClient, setIsEnablePopupClient] = useState(false);
  const [isEnableSkipButton, setIsEnableSkipButton] = useState(false);
  const [isEnableTableReservationPopup, setIsEnableTableReservationPopup] = useState(false);

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [mobile, setMobile] = React.useState('');

  const bg = {
    1: '#86A789',
    2: '#D2E3C8',
    3: '#C2CCC5',
    4: '#CA96A1',
    5: '#FEF5DA',
    6: '#BACD92',
    7: '#E3C770',
    8: '#F1C7BF',
    9: '#ECF9FC',
    0: '#D2A578'
  }

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleName = (event) => {
    setName(event.target.value);
  };

  const handleMobile = (event) => {
    setMobile(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("clientInfo", JSON.stringify({ name: name, email: email, mobile: mobile }));
    localStorage.setItem("isModelFalg", 1);
    setIsScanModel(false)
    setNestedModal(false)
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: 'en,el,fr,it,ar,es,pt,de',
        autoDisplay: true,
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        multilanguagePage: true
      },
      "google_translate_element"
    );
  };

  const closeAll = () => {
    setIsScanModel(false)
    setNestedModal(false)
    localStorage.setItem("isModelFalg", 1);
  }

  const handleBackClick = () => {
    setShow(false)
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const assetId = urlSearchParams.get("assetId");
    localStorage.removeItem("printData");
    setAssetIds(assetId)
    if (assetId !== localStorage.getItem("assetId")) {
      localStorage.setItem("assetId", assetId);
    }
    dispatch(onGetMenu({ assetId: `${assetId}` }));

    dispatch(onGetAssetInfo({ assetId: `${assetId}` }));
    localStorage.setItem("assetInfoData", JSON.stringify(asset[0]));
    setTimeout(function () {
      if (localStorage.getItem("assetInfoData")) {
        let assetInfo = JSON.parse(localStorage.getItem("assetInfoData"));
        dispatch(onGetCategories({ venueId: assetInfo.venueId }));
      }
    }, 3000);
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    if (localStorage.getItem("isModelFalg") == 1) {
      setIsScanModel(false)
      setNestedModal(false)
    }

  }, [dispatch, menuAddUpdate, categoriesAddUpdate]);

  useEffect(() => {
    if (asset.length > 0) {
      setAssetInfo(asset[0]);
      localStorage.setItem("assetInfoData", JSON.stringify(asset[0]));
    }
    if (!assetInfo) {
      setAssetInfo(JSON.parse(assetInfo));
    }
  }, [asset])

  useEffect(() => {
    window.addEventListener("scroll", handlePaggination);
    return () => window.removeEventListener("scroll", handlePaggination);
  }, []);

  setTimeout(function () {
    if (asset.length > 0) {
      localStorage.setItem("assetInfoData", JSON.stringify(asset[0]));
      if (asset[0].venueInfo.setting !== undefined)
        setModelSetting(asset[0].venueInfo.setting.modelB)
    }
  }, 3000);

  useEffect(() => {
    if (page === 1) {
      setMenuList(menu);
    } else {
      if (menu && menu.length > 0 && menuList)
        setMenuList((prevItems) => [...prevItems, ...menu]);
    }
  }, [menu]);

  useEffect(() => {
    const assetId = localStorage.getItem("assetId");
    dispatch(
      onGetMenu({
        categoryId: selectedCategoryId,
        subCategoryId: selectedSubCategoryId,
        page: `${page}`,
        assetId: `${assetId}`,
      })
    );
  }, [page, dispatch, selectedCategoryId]);

  const handlePaggination = async () => {
    try {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        setPage((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryClick = (event) => {
    if (!event) {
      setSelectedSubCategoryId("");
      setSelectedCategoryId("");
      setSelectedCategory();
    } else {
      if (event.subCategories) {
        setSelectedCategoryId(event ? event._id : "");
        setSelectedSubCategoryId("");
        setSelectedCategory(event);
      } else {
        setSelectedSubCategoryId(event ? event._id : "");
        setSelectedCategoryId("");
      }
    }
    if (page === 1) {
      const assetId = localStorage.getItem("assetId");
      if (event && event.subCategories) {
        dispatch(onGetMenu({ categoryId: event ? event._id : "", assetId: `${assetId}` }));
      } else {
        dispatch(onGetMenu({ subCategoryId: event ? event._id : "", assetId: `${assetId}` }));
      }
      setMenuList(menu);
    }
    else {
      setPage(1);
    }
    if (isCategoryModal == true) {
      setIsCategoryModal(false);
    }
  };

  const callWaiter = () => {
    if (asset.length > 0) {
      let payload = {
        assetId: asset[0]._id,
        venueAreasId: asset[0].venueAreasId,
        venueId: asset[0].venueId,
        title: `Call For Waiter`,
        message: `Hello KDS someone user call for a waiter on the venue ${asset[0].venueInfo['name']} asset table ${asset[0].assetName}`,
        type: "CallWaiter",
      }
      dispatch(onAddNotification(payload))
      toast.success("Successfully Called please wait for sometime.")
    }
  }

  const addInstructions = (e, item) => {
    let cardItem = localStorage.getItem("cartItems");
    if (cardItem) {
      cardItem = JSON.parse(cardItem)
      cardItem = cardItem.map((cItem) => {
        if (cItem._id == item._id)
          cItem.instruction = e;
        return cItem;
      })
      localStorage.setItem('cartItems', JSON.stringify(cardItem));
    }
  };

  const handleAddToCart = (item) => {
    if (!modelSetting) {
      return false;
    }
    if (!isInCart(item._id)) {
      setModelItem(item);
    }
    dispatch(addToCart(item));
  };

  const viewDescription = (item) => {
    setPopupItem(item);
    setShow(true);
  };

  const handleDecreseFromCart = (id) => {
    dispatch(decreaseFromCart(id));
  };

  const isInCart = (id) => {
    const itemIndex = cartItems.findIndex((item) => item._id === id);
    if (itemIndex >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const getQuantity = (id) => {
    const itemIndex = cartItems.findIndex((item) => item._id === id);
    return cartItems[itemIndex].quantity;
  };

  const handleViewCartClick = () => {
    navigate("/payment");
  };

  // Filter the data based on filterTerm
  let filteredMenu = [];
  if (menuList) {
    filteredMenu = menuList.filter((item) => {
      const matchesSearchTerm = item.name.toLowerCase().includes(filterTerm.toLowerCase());
      return matchesSearchTerm;
    });
  }

  const handleModalClose = () => {
    setVideoShow(false)
    setShow(false)
  }

  const categoryFunctionProps = {
    handleCategoryClick: handleCategoryClick,
  }

  useEffect(() => {
    if (asset.length > 0) {
      if (asset[0].venueInfo.setting !== undefined) {
        setIsMenuCardView(asset[0].venueInfo.setting.itemTabView)
        if (asset[0].venueInfo.setting.catTabView)
          setShowCategoryOnMenu(asset[0].venueInfo.setting.catTabView)

        if (asset[0].venueInfo.setting.catListView)
          setShowCategoryOnPopup(asset[0].venueInfo.setting.catListView)

        if (asset[0].venueInfo.setting.activeClientInfo)
          setIsEnablePopupClient(asset[0].venueInfo.setting.activeClientInfo)

        if (asset[0].venueInfo.setting.isRequiredClientInfo)
          setIsEnableSkipButton(asset[0].venueInfo.setting.isRequiredClientInfo)

        if (asset[0].venueInfo.setting.activeTableReservation)
          setIsEnableTableReservationPopup(asset[0].venueInfo.setting.activeTableReservation)

      }
    }
  }, [asset]);

  const toggleNested = () => {
    setNestedModal(!nestedModal);
  };

  return (
    <>
      {/* <Header />   */}
      <div className="main-container">
        <div className="mobile-menu mb-5">
          <div className="mobile-header d-flex justify-content-between align-items-center" style={{ backgroundImage: `url(${backgroundImageUrl || backgroundImageUrl})` }}>
            <div className="p-3 _cursor-pointer" style={{ width: "5rem", height: "5rem" }} >
              {assetInfo.floorPlanImage && <Image className="w-100" src={assetInfo.floorPlanImage} />}
            </div>
          </div>
          <Row className="p-0 m-0 p-2">
            <Col xs={12} md={3} className="m-0 p-0">
              <div className="mobile-search-container position-relative">
                <div className='input-search-icon'>
                  <CiSearch size={25} />
                </div>
                <input
                  type='text'
                  value={filterTerm}
                  onChange={(e) => setFilterTerm(e.target.value)}
                  className='_search-input border-0 rounded-pill p-2 px-5 shadow-sm w-100 fw-bold'
                  placeholder='Search'
                />
                <div className='input-adjust-icon'>
                  <HiOutlineAdjustmentsHorizontal onClick={() => setIsCategoryModal(true)} size={25} />
                </div>
              </div>
            </Col>
            <Col xs={6} md={2} className="d-flex justify-content-center text-center m-0 p-0">
              <div className='call-waiter-button _bg-col-voilet text-light rounded-pill d-flex justify-content-between align-items-center cursor-pointer p-1' style={{ whiteSpace: 'nowrap' }}>
                <h6 className='p-0 m-0 px-2 d-block fw-bold' onClick={() => callWaiter()}>Call Waiter</h6>
                <div className='call-waiter-icon rounded-circle _bg-light-green p-1 m-1 d-flex just-fy-content-center align-items-center'>
                  <IoIosCall height={10} width={10} />
                </div>
              </div>
            </Col>
            <Col xs={6} md={7} className="language-choice-container text-end m-0 p-0 overflow-scroll">
              <Dropdown className="_cursor-pointer">
                <div className="google-trans" id="google_translate_element"></div>
              </Dropdown>
            </Col>
          </Row>
          {categories && categories.length > 0 &&
            <div className="mobile-category-container py-2 mt-4 py-4 fw-bold">
              {/* <span onClick={() => handleCategoryClick()} className={`rounded-pill p-2 px-3 mx-1 text-nowrap ${!selectedCategory && 'selected-category border-0 text-light'}`}>All</span> */}
              {categories.map((category, index) => (
                <span key={index} onClick={() => handleCategoryClick(category)} className={`rounded-pill p-2 px-3 mx-1 text-nowrap _cursor-pointer ${selectedCategory && selectedCategory._id == category._id && 'selected-category border-0'}`}>{category.name}</span>
              ))}
            </div>}
          {selectedCategory && selectedCategory.subCategories.length > 0 &&
            <div className="mobile-category-container py-2 pb-4 fw-bold">
              {selectedCategory.subCategories.map((subCategory, index) => (
                <span key={index} onClick={() => handleCategoryClick(subCategory)} className={`rounded-pill p-2 px-3 mx-1 text-nowrap _cursor-pointer ${selectedSubCategoryId == subCategory._id && 'selected-category border-0'}`}>{subCategory.name}</span>
              ))}
            </div>}
          <div className="menu-container mt-4">
            <Row className="p-0 m-0">
              {filteredMenu && filteredMenu.map((menu, index) => (
                <Col xs={6} sm={4} key={index} className="m-0 p-2 col-lg-2-1">
                  <div className="m-1 p-2 mobile-menu-item position-relative" style={{ backgroundColor: !isInCart(menu._id) ? bg[index % 10] : "#FDFABD" }}>
                    <div onClick={() => viewDescription(menu)} className="menu-item-img-container rounded d-flex justify-content-center align-items-center overflow-hidden w-100 my-4 position-relative _cursor-pointer">

                      {isInCart(menu._id) && <div className="overlay p-2 d-flex justify-content-end">
                        <FaCheckCircle size={20} />
                      </div>}

                      <Image onClick={() => viewDescription(menu)}
                        className="menu-item-img h-100 w-100 rounded"
                        src={menu.image}
                      />
                    </div>

                    <div className="d-flex justify-content-between align-items-center pb-2">
                      <h6 className="m-0 p-0 _font-large">{menu.name}</h6>
                      {(menu.isGlutenFree && menu.isGlutenFree === true) &&
                        <LuVegan size={20} className="text-success" />}
                    </div>

                    <p className="p-0 m-0 fw-bold">€ {menu.price}</p>
                    {isInCart(menu._id) && modelSetting ?
                      <div className="d-flex align-items-center mobile-menu-plus-icon position-absolute _cursor-pointer">
                        <FaMinus onClick={() => handleDecreseFromCart(menu._id)} size={30} className="p-2" />
                        <h6 className="m-0">{getQuantity(menu._id)}</h6>
                        <FaPlus onClick={() => handleAddToCart(menu)} size={30} className="p-2" />
                      </div>
                      :
                      <FaPlus onClick={() => handleAddToCart(menu)} size={30} className="mobile-menu-plus-icon position-absolute p-2 _cursor-pointer" />
                    }
                  </div>
                </Col>))}
            </Row>
          </div>
        </div>
        {cartItems.length > 0 && modelSetting && (
          <div className="w-100 position-fixed bottom-0 end-0 px-4 pb-1 d-flex justify-content-center" onClick={handleViewCartClick}>
            <div
              className="view-cart _bottom-shadow _bg-orange fw-bold rounded d-flex justify-content-between p-2 px-3 text-light"
              // style={{ width: "20rem" }}
            >
              <div>
                <span>{cartItems.length} items</span>
              </div>
              <div

                className="d-flex align-items-center justify-content-center _cursor-pointer"
              >
                <span className="px-2 text-light">View Cart</span>
                <span className="text-light">
                  <IoIosArrowForward />
                </span>
              </div>
            </div>
          </div>
        )}

        <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
          <div className="_model-img-container position-relative">
            {!videoShow ?
              <IoPlay
                onClick={() => setVideoShow(true)}
                className="_play-icon _z-index-2 rounded-circle bg-light position-absolute p-1 _cursor-pointer"
                size={25}
              />
              :
              <IoMdArrowRoundBack
                onClick={() => setVideoShow(false)}
                className="_play-icon _z-index-2 rounded-circle bg-light position-absolute p-1 _cursor-pointer"
                size={25}
                style={{ zIndex: "2" }}
              />}
            <IoClose
              className="_close-icon _z-index-2 rounded-circle bg-light position-absolute p-0 _cursor-pointer"
              size={25}
              onClick={() => handleModalClose()}
            />
            {!videoShow ?
              <Image
                className="_obj-fit-cover h-100 w-100"
                src={popupItem.image}
                fluid
              /> :
              <video className="_obj-fit-cover h-100 w-100" loop autoPlay>
                <source src='https://dm0qx8t0i9gc9.cloudfront.net/watermarks/video/GtuAR0I/videoblocks-thick-american-style-homemade-on-fluffy-dough-pizzas-with-various-ingredients-placed-on-a-brown-wooden-table_bp6drzxb8__d84175274e18cf40a18e8e32b6179344__P360.mp4' type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            }
          </div>
          <Modal.Body className="d-flex justify-content-center">
            <div className="_model-content px-2">
              <h3>{popupItem.name}</h3>
              <div className="font-manage">{popupItem.description}</div>
              <Table hover>
                <tbody>
                  <tr>
                    <td> {<div className="fw-bold">€ {popupItem.price} </div>}</td>
                    <td>
                      {isInCart(popupItem._id) && modelSetting ? (
                        <div className=" d-flex justify-content-center  text-dark">
                          <CiCircleMinus size={20}
                            onClick={() => handleDecreseFromCart(popupItem._id)}
                          />
                          <h5 className="px-1 p-0 m-0 small-font">
                            {getQuantity(popupItem._id)}
                          </h5>
                          <IoIosAddCircleOutline size={20}
                            onClick={() => handleAddToCart(popupItem)}
                          />
                        </div>
                      ) :
                        <div className=" d-flex justify-content-center ">
                          <h5
                            onClick={() => handleAddToCart(popupItem)}
                            className={` p-0 px-2 m-0 small-font`}
                          >
                            <IoIosAddCircle size={20} />
                          </h5>
                        </div>}</td>
                  </tr>
                </tbody>
              </Table>
              {/* <h6 className="fw-bold">Add Supplement</h6> */}
              {(popupItem.addOnsInfo != undefined && popupItem.addOnsInfo.length > 0)
                ?
                (
                  popupItem.addOnsInfo.map((addOns, index) => (
                    <Card className="_bottom-shadow mt-2 _cursor-pointer">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <div className="d-flex align-items-center">
                          <LuSquareDot size={15} className="_text-green" />
                          <h6 className="p-0 m-0 px-2">{addOns.name} € {addOns.price}</h6>
                        </div>

                        {isInCart(addOns._id) && modelSetting ? (
                          <div className="py-1 d-flex justify-content-center align-items-center text-dark">
                            <CiCircleMinus size={20}
                              onClick={() => handleDecreseFromCart(addOns._id)}
                            />
                            <h5 className="px-1 p-0 m-0 small-font">
                              {getQuantity(addOns._id)}
                            </h5>
                            <IoIosAddCircleOutline size={20}
                              onClick={() => handleAddToCart(addOns)}
                            />
                          </div>
                        ) : (
                          modelSetting ?
                            <div className="p-1 d-flex justify-content-center align-items-center">
                              <h5
                                onClick={() => handleAddToCart(addOns)}
                                className={` p-0 px-2 m-0 small-font`}
                              >
                                <IoIosAddCircle size={20} />
                              </h5>
                            </div>
                            : <h5
                              className={` p-0 m-0 small-font`}
                            >
                            </h5>
                        )}
                      </div>
                    </Card>
                  ))
                )
                :
                <InputGroup className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-default">
                    Instructions
                  </InputGroup.Text>
                  <Form.Control as="textarea"
                    onChange={(event) => addInstructions(event.target.value, popupItem)}
                    aria-label="Default"
                    placeholder="Leave Instructions"
                    aria-describedby="inputGroup-sizing-default"
                  />
                </InputGroup>
              }
              {/* <Card className="_bottom-shadow mt-2 _cursor-pointer">
              <div className="d-flex justify-content-between align-items-center p-2">
                <div className="d-flex align-items-center">
                  <LuSquareDot size={15} className="_text-green" />
                  <h6 className="p-0 m-0 px-2">Strawberry</h6>
                </div>
                <FaRegDotCircle size={12} className="_text-light-orange" />
              </div>
            </Card> */}
              <div className="item-description-footer d-flex justify-content-center mt-4">
                <div className="description-footer-content">
                  {/* <button 
                  onClick={() => setShow(false)}
                  className="_cont-payment-btn bg-dark p-2 m-0 rounded _cursor-pointer mx-1 border-0">
                    Back
                  </button>
                  <button
                    onClick={handleViewCartClick}
                    className="_cont-payment-btn _bg-orange p-2 m-0 rounded _cursor-pointer mx-1 border-0"
                  >
                    Add To Order
                  </button> */}
                  {/* <div className="footer-content-action-buttons _cursor-pointer">
                    {isInCart(popupItem._id) && <FaMinus onClick={() => handleDecreseFromCart(popupItem._id)} className="border rounded-pill" />}
                    {isInCart(popupItem._id) && <span className="px-2">{getQuantity(popupItem._id)}</span>}
                    <FaPlus onClick={() => handleAddToCart(popupItem)} className="border rounded-pill" />
                  </div> */}

                  <Button onClick={handleBackClick} variant="warning">
                    {/* <FaArrowLeft
                      size={12}
                      className=""
                    /> */}
                    Back</Button>
                  <div className="action-button-cart text-light fw-bold d-flex justify-content-between _cursor-pointer">
                    <p onClick={handleViewCartClick} className="px-1 p-0 m-0">Add To Order</p>
                    <p className="px-3 p-0 m-0">€{isInCart(popupItem._id) ? (popupItem.price * getQuantity(popupItem._id)).toFixed(2) : '0'}</p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {(isEnablePopupClient || isEnableTableReservationPopup) ?
          < Modal show={isScanModel} fullscreen={false} onHide={() => setIsScanModel(false)} size="lg" backdrop="static">
            <Modal.Body className="d-flex justify-content-center">
              <div className="_model-content px-2">
                <h3><Image width='10%' src={assetInfo.floorPlanImage} />{(assetInfo.venueInfo) ? assetInfo.venueInfo.name : ""}</h3>
                {/* <div className="font-manage"><Image className="w-100" src={assetInfo.floorPlanImage} /></div> */}
                {(isEnablePopupClient) ?
                  <div>
                    <Card className="_bottom-shadow mt-2 _cursor-pointer">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <div className="d-flex align-items-center">
                          <LuShoppingCart size={50} className="_text-green" />
                          <h5 onClick={toggleNested} className="p-0 m-0 px-2"> Click here for Iteams Order</h5>
                        </div>
                      </div>
                    </Card>
                  </div> : ""}
                {(isEnableTableReservationPopup) ?
                  <Card className="_bottom-shadow mt-2 _cursor-pointer">
                    <div className="d-flex justify-content-between align-items-center p-2">
                      <div className="d-flex align-items-center">
                        <LuClock size={50} className="_text-green" />
                        <h5 className="p-0 m-0 px-2">Click here for Table Reservation</h5>
                      </div>
                    </div>
                  </Card> : ""}
                <Modal show={nestedModal} fullscreen={false} onHide={() => setIsScanModel(false)} size="lg" backdrop="static">
                  <Modal.Body className="d-flex justify-content-center">
                    <div className="_model-content px-2">
                      <h3><Image width='10%' src={assetInfo.floorPlanImage} />{(assetInfo.venueInfo) ? assetInfo.venueInfo.name : ""}</h3>
                      <div>
                        <Form onSubmit={handleSubmit}>
                          <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" placeholder="Full Name" name="name" value={name}
                              onChange={handleName} />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" name="email" value={email}
                              onChange={handleEmail} />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control type="number" placeholder="Enter Mobile" name="mobile" value={mobile}
                              onChange={handleMobile} />
                          </Form.Group>
                          <Button onClick={() => closeAll()} variant="secondary">Skip</Button>{" "}
                          <Button type="submit" variant="primary">Next Step</Button>
                        </Form>

                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
                {/* <div className="d-flex justify-content-center mt-2">
                <h4
                  onClick={handleViewCartClick}
                  className="_cont-payment-btn _bg-orange p-1 px-2 m-0 rounded _cursor-pointer"
                >
                  Skip
                </h4>
              </div> */}
              </div>
            </Modal.Body>
          </Modal>
          : ""}
        {
          isCategoryModal &&
          <CategoryModal onClick={() => setIsCategoryModal(false)} categoryFunctionProps={categoryFunctionProps} FunctionProps data={isCategoryModal} categories={categories} />
        }
      </div >
    </>
  );
};

export default Menu;