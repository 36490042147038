import React, { useEffect, useState } from "react";
import "../../assets/styles/Header.css";
import "../../assets/styles/Global.css";
import BarmouLogo from "../../assets/images/barmou-logo.png";
import backgroundImageUrl from "../../assets/images/banner.png";
import AWinSoftLogo from "../../assets/images/a-win-soft-logo.png";
import GoogleReview from "../../assets/images/google-review.png";
import { Image, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAssetInfo as onGetAssetInfo
} from "../../slices/thunks";
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [assetInfo, setAssetInfo] = useState({});
  const { asset } =
    useSelector((state) => ({
      asset: state.assets.asset
    }));

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlSearchParams = new URLSearchParams(new URL(currentUrl).search);
    const assetId = urlSearchParams.get("assetId");
    dispatch(onGetAssetInfo({ assetId: `${assetId}` }));
    let assetInfo = localStorage.getItem("assetInfoData");
  }, [navigate]);

  const handleGoogleReviewClick = () => {
    navigate('/review')
  }

  setTimeout(function () {
    if (asset.length > 0) {
      setAssetInfo(asset[0]);
      localStorage.setItem("assetInfoData", JSON.stringify(asset[0]));
    }
    if (!assetInfo) {
      setAssetInfo(JSON.parse(assetInfo));
    }
  }, 2000);

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: 'en,el,fr,it,ar,es,pt,de',
        autoDisplay: true,
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        multilanguagePage: true
      },
      "google_translate_element"
    );
  };

  return (
    <>
      <div className="header-container" style={{ backgroundImage: `url(${assetInfo.bannerFile || backgroundImageUrl})` }}>
        <div className="header-content d-flex justify-content-between align-items-center">
          <div
            className="p-3 _cursor-pointer"
            style={{ width: "5rem", height: "5rem" }}
          >
            {(assetInfo.floorPlanImage) ? <Image className="w-100" src={(assetInfo.floorPlanImage) ? assetInfo.floorPlanImage : AWinSoftLogo} /> : ""}
          </div>
          {/* <div
            className="p-3 _cursor-pointer"
            style={{ width: "10rem", height: "5rem", color: "#ffffff" }}
          >
            {(assetInfo.name) ? assetInfo.name : assetInfo.name}
          </div> */}
          {/* <h1 className="logo-name fw-bold _cursor-pointer">{(assetInfo.name) ? assetInfo.name : assetInfo.name}</h1>
          <div
            className="_cursor-pointer"
            style={{ width: "5rem", height: "5rem" }}
          >
            <Image className="w-100" src={AWinSoftLogo} />
          </div> */}
          <Dropdown className="_cursor-pointer p-3">
            <div className="google-trans" id="google_translate_element"></div>
          </Dropdown>
        </div>
        <div className="d-flex justify-content-end">
          <div
            onClick={handleGoogleReviewClick}
            className="p-2 mt-1 _cursor-pointer"
            style={{ width: "4rem", height: "4rem" }}
          >
            <Image className="w-100" src={GoogleReview} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
