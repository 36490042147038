import React from "react";
import { useEffect, useState } from "react";
import { Image, Modal, Form, Button, Accordion, Table, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AWinSoftLogo from "../../assets/images/a-win-soft-logo.png";
import { FaStar, FaPrint } from "react-icons/fa";
import QRCode from '../../assets/images/qr.png'
import { IoMdArrowRoundBack, IoIosCall } from "react-icons/io";
import {
    addToCart,
    decreaseFromCart,
    clearCart,
    getTotal,
} from "../../slices/Cart/reducer";
import {
    resetOrderApiResponseFlag as onResetOrderApiResponseFlag,
    getAssetInfo as onGetAssetInfo,
    addNotification as onAddNotification
} from "../../slices/thunks";
import "../../assets/styles/Payment.css";
import "../../assets/styles/Global.css";
import { toast } from 'react-toastify';

const Print = () => {
    const { cartItems, cartTotalAmout, orderStatusCode, cart, cartTotalVat, cardNetAmount, orderPrintRes, asset } = useSelector(
        (state) => ({
            cart: state.cart,
            cartItems: state.cart.cartItems,
            cartTotalAmout: state.cart.cartTotalAmout,
            cardNetAmount: state.cart.cardNetAmount,
            cartTotalVat: state.cart.cartTotalVat,
            orderStatusCode: state.orders.orderStatusCode,
            orderPrintRes: state.orders.orderPrintRes,
            asset: state.assets.asset
        })
    );
    const [assetId, setAssetId] = useState("");
    const [assetInfo, setAssetInfo] = useState('');
    const [tipsData, setTipAmount] = useState({});
    const [printData, setPrintData] = useState({});
    const [isOrderAccepted, setIsOrderAccepted] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const callWaiter = () => {
        if (asset.length > 0) {
            let payload = {
                assetId: asset[0]._id,
                venueAreasId: asset[0].venueAreasId,
                venueId: asset[0].venueId,
                title: `Call For Waiter`,
                message: `Hello KDS someone user call for a waiter on the venue ${asset[0].venueInfo['name']} asset table ${asset[0].assetName}`,
                type: "CallWaiter",
            }
            dispatch(onAddNotification(payload))
            toast.success("Successfully Called please wait for sometime.")
        }
    }

    useEffect(() => {
        setAssetId(localStorage.getItem("assetId"));
        if (assetId !== null) {
            dispatch(onGetAssetInfo({ assetId: assetId }));
        }
        localStorage.removeItem("isModelFalg");
        localStorage.removeItem("clientInfo");
    }, [assetId, dispatch]);

    useEffect(() => {
        dispatch(getTotal());
        setTimeout(function () {
            console.log('assetasset', asset)
            if (asset != undefined && asset.length > 0)
                localStorage.setItem("assetInfoData", JSON.stringify(asset[0]));
        }, 2000);
    }, [cartItems, asset, dispatch]);

    useEffect(() => {
        if (Object.keys(orderPrintRes).length !== 0) {
            if (localStorage.getItem("printData")) {
                setPrintData(JSON.parse(localStorage.getItem("printData")))
            } else {
                localStorage.setItem('printData', JSON.stringify(orderPrintRes));
                setPrintData(JSON.parse(JSON.stringify(orderPrintRes)))
            }
            dispatch(clearCart());
        } else {
            setPrintData(JSON.parse(localStorage.getItem("printData")))
            if (!localStorage.getItem("printData")) {
                navigate(`/menu?assetId=${assetId}`);
            }
        }
        setAssetId(localStorage.getItem("assetId"));
    }, []);

    useEffect(() => {
        let assetInfo = localStorage.getItem("assetInfoData");
        if (assetInfo) {
            setAssetInfo(JSON.parse(assetInfo));
            assetInfo = JSON.parse(assetInfo);
            if (assetInfo.venueInfo.setting !== undefined)
                if (!assetInfo.venueInfo.setting.modelB) {
                    navigate(`/menu?assetId=${localStorage.getItem("assetId")}`);
                }
        }
    }, [navigate]);

    useEffect(() => {
        if (orderStatusCode != null) {
            //setShow(true);
            if (orderStatusCode === 200) {
                setIsOrderAccepted(true);
                //dispatch(clearCart());
                const timeoutId = setTimeout(() => {
                    dispatch(onResetOrderApiResponseFlag());
                    navigate(`/review`);
                }, 2000);
                return () => clearTimeout(timeoutId);
            } else {
                //setOrderMessage(orderMessage);
                const timeoutId = setTimeout(() => {
                    dispatch(onResetOrderApiResponseFlag());
                    navigate(`/menu?assetId=${assetId}`);
                }, 3000);

                return () => clearTimeout(timeoutId);
            }
        }
    }, [orderStatusCode, dispatch, assetId, navigate]);

    // Handle Add To Cart
    const handleAddToCart = (item) => {
        dispatch(addToCart(item));
    };

    // Handle Remove From Cart
    const handleDecreseFromCart = (id) => {
        dispatch(decreaseFromCart(id));
    };

    const handleBackClick = () => {
        navigate(`/menu?assetId=${assetId}`);
    };

    const redirectOnWay = (r) => {
        if (r == 'review') {
            navigate(`/review?assetId=${assetId}`);
        } else if (r == 'main') {
            navigate(`/menu?assetId=${assetId}`);
        } else if (r == 'print') {

        }
    }

    const handleReviewClick = (url) => {
        window.open(url, '_blank');
    }

    return (
        <>
            <div className="d-flex justify-content-center p-4 px-lg-5">
                <div className="_payment-container">
                    {/* Navigation */}
                    {/* <div className="d-flex align-items-center">
                        <FaArrowLeft
                            onClick={handleBackClick}
                            size={20}
                            className="text-muted _cursor-pointer"
                        />
                        <h6 className="_font-l p-0 m-0 px-3">{(assetInfo.name) ? assetInfo.name : "AwinSoft"}</h6>
                    </div> */}
                    {/* items */}
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <div className="col-6">
                                    <div className="col-6 text-danger bold-text">
                                        {(assetInfo.floorPlanImage) ? <Image className="img-width" src={(assetInfo.floorPlanImage) ? assetInfo.floorPlanImage : AWinSoftLogo} /> : ""}{(assetInfo.name) ? assetInfo.name : "AwinSoft"}</div>
                                    <div className="col-6 text-primary bold-text">ORDER ID #{printData.orderId}</div></div>
                                <div className="col-6">
                                    <div className="col-6 text-dark">DATE {printData.date}</div>
                                    <div className="col-6 text-dark">TIME {printData.time}</div>
                                </div>
                            </Accordion.Header>

                            <Accordion.Body>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Item</th>
                                            <th>Quantity</th>
                                            <th>Price</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(printData).length !== 0 && printData.invoiceDetails.map((item, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.entityName}</td>
                                                <td>{Math.trunc(item.quantity)}</td>
                                                <td>&#8364;{" "}{(Number((Math.floor((item.totalValue / Math.trunc(item.quantity)) * 100) / 100))).toFixed(2)}</td>
                                                <td>&#8364;{" "}{item.totalValue}</td>
                                            </tr>
                                        ))}
                                        <tr><td></td><td colspan="4">
                                            <div className="d-flex justify-content-between p-2 px-4">
                                                <h6 className="p-0 m-0 text-muted">Items Net Value</h6>
                                                <h6 className="p-0 m-0">
                                                    &#8364;{" "}
                                                    {Object.keys(printData).length !== 0 ? printData.invoiceSummary.totalNetValue : 0}
                                                </h6>
                                            </div>
                                            <div className="d-flex justify-content-between p-2 px-4">
                                                <h6 className="p-0 m-0 text-muted">
                                                    Vat and Restaurant Charges
                                                </h6>
                                                <h6 className="p-0 m-0">&#8364; {Object.keys(printData).length !== 0 ? printData.invoiceSummary.totalVatAmount : 0}</h6>
                                            </div>
                                            <div className="d-flex justify-content-between p-2 px-4">
                                                <h6 className="p-0 m-0 text-muted">
                                                    Tip {Object.keys(printData).length !== 0 ? printData.tipData.tipPercentage : 0}%
                                                </h6>
                                                <h6 className="p-0 m-0">&#8364; {Object.keys(printData).length !== 0 ? printData.tipData.tipAmount : 0}</h6>
                                            </div>
                                            <div className="d-flex justify-content-between p-2 border-top px-4">
                                                <h6>To Pay</h6>
                                                <h6 className="fw-bold">
                                                    &#8364;{" "}
                                                    {Object.keys(printData).length !== 0 ? printData.invoiceSummary.totalValue : 0}
                                                </h6>
                                            </div>
                                            <div className="d-flex justify-content-between p-2 border-top px-4">
                                                <h6 className="p-0 m-0 text-muted">Thank you for order, Please proceed to pay.</h6>
                                            </div>
                                        </td></tr>
                                        <tr><td colspan="5">
                                            <div className="d-flex justify-content-between ">
                                                <div className="col-12">
                                                    {/* <Button onClick={() => redirectOnWay("review")} className="_bg-col-voilet text-light rounded border-0 py-1" variant="primary">Review</Button>{' '} */}
                                                    <Button key='1' onClick={() => redirectOnWay("print")} className="_bg-col-voilet text-light rounded border-0 py-1" variant="info"> <FaPrint size={18} className=' mx-1' /> Print</Button>{' '}
                                                    <Button key='2' onClick={() => redirectOnWay("main")} className="_bg-col-voilet text-light rounded border-0 py-1" variant="success">Order Again</Button>{' '}
                                                    <Button key='3' className="_bg-col-voilet text-light rounded border-0 py-1" variant="danger" onClick={() => callWaiter()}><IoIosCall height={10} width={10} /> Call Waiter</Button>{' '}
                                                </div>
                                            </div>
                                        </td></tr>
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <Table bordered>
                        <tbody>
                            <tr>
                                <th className="d-none d-sm-block">Note</th>
                                <th>Platform</th>
                                <th className="d-none d-sm-block">QR</th>
                                <th>Rating</th>
                                <th>Action</th>
                            </tr>
                            {(asset && asset.length > 0) && (asset[0].venueInfo.review.map((review, index) => (
                                <tr>
                                    <td className="d-none d-sm-block">If you enjoyed our place we would appreciate a 5 star review thanks</td>
                                    <td>{review.name}</td>
                                    <td className="d-none d-sm-block"> <Image className='_obj-fit-cover w-50 h-50' src={review.qr} /></td>
                                    <td>
                                        <div className="rating-number"> {review.rating}</div>
                                        <div className="rating-display d-none d-sm-block text-nowrap">  {Array.from({ length: review.rating }, (_, index) => (
                                            <FaStar size={15} className='text-warning mx-1' />
                                        ))}</div>
                                        {/* <div class="elfsight-app-b662f13b-4cbf-4381-8b1c-6514c48ee7b8" data-elfsight-app-lazy></div> */}
                                    </td>
                                    <td> <Button onClick={() => handleReviewClick(review.link)} className='p-2 bg-dark fw-bold border-0 text-nowrap'>Rate Us</Button></td>
                                </tr>
                            )))}

                        </tbody>
                    </Table>

                    {/* Bill Details */}
                </div>
            </div>
        </>
    );
};

export default Print;
