import { React, useState } from 'react'
import { Modal, Image, Row, Col } from 'react-bootstrap'
import { IoClose } from "react-icons/io5";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import './CategoryModal.css'
import { FaFilter } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { SlArrowDown } from "react-icons/sl";

const CategoryContainer = (props) => {

  const categories = props.categories;
  const [selectedSubCategory, setSelectedSubCategory] = useState();

  const bg = {
    1: '#D8D8A4',
    2: '#BDD4A5',
    3: '#D8E79D',
    4: '#FFBBBB',
    5: '#F4F4A0',
    6: '#FFCAB1',
    7: '#CCE698',
    8: '#DEE4B9',
    9: '#E7E3E0',
    0: '#FFD3B5'
  }

  return (
    <>
      <Modal show={props.data ? true : false} fullscreen={true} onHide={props.onClick}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className='d-flex'>
              <div className='d-flex align-items-center'>
                {selectedSubCategory ?
                  <IoMdArrowRoundBack onClick={() => setSelectedSubCategory()} size={20} />
                  :
                  <IoMdArrowRoundBack onClick={props.onClick} size={20} />
                }
                <h3 onClick={() => setSelectedSubCategory()} className='p-1 m-0'>Category</h3>
              </div>
              {selectedSubCategory &&
                <div className='d-flex align-items-center'>
                  <FaChevronRight size={15} className='mx-1' />
                  <h3 className='p-1 m-0'>{selectedSubCategory.name}</h3>
                </div>
              }
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='d-flex justify-content-center'>
          {!selectedSubCategory ?
            <div className='mobile-category-modal-container'>
              {categories.map((category, index) => (
                <div className='d-flex align-items-center p-2 rounded-pill mt-3' style={{ backgroundColor: bg[index % 10] }}>
                  <div className='rounded-circle mx-1 ' style={{ width: "2rem", height: "2rem" }}>
                    <Image className="_obj-fit-cover h-100 w-100" src={category.image} />
                  </div>
                  <div className='d-flex align-items-center justify-content-between w-100'>
                    <h5 className='m-0'>{category.name}</h5>
                    <div className='category-actions'>
                      <button onClick={() => setSelectedSubCategory(category)} className='mx-1'><SlArrowDown></SlArrowDown></button>
                      <button onClick={() => props.categoryFunctionProps.handleCategoryClick(category)}><FaFilter></FaFilter></button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            :
            <div className='mobile-category-modal-container'>
              <h6 className='m-0 p-0'>Category ▼</h6>
              <div onClick={() => props.categoryFunctionProps.handleCategoryClick(selectedSubCategory)} className='d-flex align-items-center p-2 rounded-pill border mt-3'>
                <div className='rounded-circle mx-1 ' style={{ width: "2rem", height: "2rem" }}>
                  <Image className="_obj-fit-cover h-100 w-100" src={selectedSubCategory.image} />
                </div>
                <div className='d-flex align-items-center justify-content-between w-100'>
                  <h5 className='m-0'>{selectedSubCategory.name}</h5>
                  <div className='category-actions'>
                    <button className='bg-dark text-light' onClick={() => props.categoryFunctionProps.handleCategoryClick(selectedSubCategory)}><FaFilter></FaFilter></button>
                  </div>
                </div>
              </div>
              <h6 className='m-0 p-0 mt-3'>Sub Categories ▼</h6>
              {selectedSubCategory.subCategories.map((subCategory, index) => (
                <div className='d-flex align-items-center p-2 rounded-pill mt-3' style={{ backgroundColor: bg[index % 10] }} onClick={() => props.categoryFunctionProps.handleCategoryClick(subCategory)}>
                  <div className='rounded-circle mx-1 ' style={{ width: "2rem", height: "2rem" }}>
                    <Image className="_obj-fit-cover h-100 w-100" src={selectedSubCategory.image} />
                  </div>
                  <div className='d-flex align-items-center justify-content-between w-100'>
                    <h5 className='m-0'>{subCategory.name}</h5>
                    <div className='category-actions'>
                      <button><FaFilter></FaFilter></button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CategoryContainer