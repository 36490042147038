import { Routes, BrowserRouter, Route } from 'react-router-dom';
import './App.css';
import Menu from './pages/Menu/Menu';
import Payment from './pages/Payment/Payment';
import Review from './pages/Review/Review';
import Print from './pages/Payment/Print';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='*' element={<Menu />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/review' element={<Review />} />
          <Route path='/print' element={<Print />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
